/* eslint-disable no-empty */
import React, { useState, useEffect } from "react";
import Header from "../../../components/header/Header";
import LeftSideBar from "../../../components/LeftSideBar/LeftSideBar";
import {
  Box,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import "./index.scss";
import UploadBatchHeader from "../../../components/UploadBatch/index";
import List from "@mui/material/List";
import CheckboxTitle from "../../../components/UploadBatchRecord/CheckboxTitle/CheckboxTitle";
import SelectedFileList from "../../../components/UploadBatchRecord/SelectedFileList/SelectedFileList";
import constants from "../../../constants/ConstantValues";
import BottomButtonsList from "../../../components/UploadBatchRecord/BottomButtonsList/BottomButtonsList";
import { useDispatch, useSelector } from "react-redux";
import {
  handleClearPDFDeleteData,
  clearApiStatus,
  listBoxFiles,
  uploadClientData,
  clearPDFUploadData,
} from "../../../redux/administrator/actions";
import { useLocation, useNavigate } from "react-router-dom";
import CustomizedSnackbars from "../../../components/snackbars/CustomizedSnackbars";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Footer from "../../../components/Footer";
import AdminFooter from "../../../components/AdminFooter";
import Label from "../../../components/label/Label";
import ProgressBar from "../../../components/ProgressBar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Index = (props) => {
  const languageData = [
    { name: "English", value: "en" },
    { name: "German", value: "german" },
    // { name: "Japanese", value: "japan" },
    // { name: "Chinese", value: "ch" },
    // { name: "Spanish", value: "es" },
    // { name: "Portuguese", value: "pt" },
    // { name: "Romanian", value: "ro" },
    // { name: "Swedish", value: "sv" },
  ];
  const dispatch = useDispatch();
  const { state } = useLocation();
  // const navigate = useNavigate()
  const history = useHistory();

  const data = useSelector((state) => state?.admin?.batchTypes);
  const { PREVIOUS, SAVE, GRANT } = constants;
  const [selectedName, setSelectedButton] = useState("pdfs");
  const [selectedBottomButton, setBottomButtonName] = useState(PREVIOUS);
  const [checkedFiles, setCheckedFiles] = useState([]);
  const [language, setLanguage] = useState("");
  const [groupData, setGroupData] = useState([]);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [message, setMessage] = useState("");
  const [err, setErr] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [severity, setSeverity] = useState("");

  // let propsData = props?.location?.state;
  // let propsData = state;
  let propsData = { ...state };

  if (propsData?.value) {
    localStorage.setItem("pvalue", propsData.value);
    localStorage.setItem("folderName", propsData.folderName);
  }

  let group = useSelector((state) => state?.admin?.boxFiles);
  const apiStatus = useSelector((state) => state?.admin?.apiStatus);
  const res = useSelector((state) => state?.admin?.uploadData);

  useEffect(() => {
    // if (selectedName === "pdfs") {
    //   const newDataArray = group?.map((item) => ({
    //     ...item,
    //     language: "en",
    //   }));
    //   setGroupData(newDataArray);
    // } else {
    setIsOpen(false);
    setGroupData(group);
    // }
  }, [group]);

  useEffect(() => {
    if (apiStatus && apiStatus !== undefined) {
      if (apiStatus?.apiError === true) {
        setMessage(apiStatus?.message);
        setErr(true);
        // dispatch(handleClearPDFDeleteData());
        dispatch(clearApiStatus());
      } else if (apiStatus?.apiSuccess === true) {
        // dispatch(handleClearPDFDeleteData());
        dispatch(clearApiStatus());
      }
    }
  }, [apiStatus, dispatch]);

  useEffect(() => {
    let data = {
      folderId: localStorage.getItem("pvalue"),
      fileType: selectedName,
    };
    dispatch(clearPDFUploadData());
    dispatch(listBoxFiles(data));
  }, [selectedName]);

  useEffect(() => {
    let pdata = localStorage.getItem("pvalue");
    let data = {
      folderId: pdata,
      fileType: selectedName,
    };
    dispatch(clearPDFUploadData());
    dispatch(listBoxFiles(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleToggle = (value) => () => {
    // const currentIndex = checkedFiles.indexOf(value);
    const currentIndex = checkedFiles.findIndex((item) => item.id === value.id);

    let newChecked = [...checkedFiles];
    // const valueId = value.id;
    //  const currentIndex = newChecked.findIndex(item => item.id === valueId);
    // const currentIndex = newChecked.indexOf(value);
    // if (!selectAllChecked) {
    //   // If "Select All" is checked, clear the current selection
    //   newChecked = [];
    // }
    // if not then push
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      //  if present then remove
      newChecked.splice(currentIndex, 1);
    }
    setCheckedFiles(newChecked);
    if (selectAllChecked) {
      setSelectAllChecked(!selectAllChecked);
    }
  };

  const removeSelectedFile = (index) => {
    let addedFiles = [...checkedFiles];
    if (index > -1) {
      addedFiles.splice(index, 1);
      setCheckedFiles(addedFiles);
    }
  };
  useEffect(() => {
    if (res?.message === "Task created" && res.apiSuccess === true) {
      setIsOpen(true);
      setMessage("Task created");
      setSeverity("success");
      // isOpen = true;
      // text = "Uploaded";
      // severity = "success"
    }
  }, [res]);

  const onSelectBottomButton = (e, action) => {
    //redirection based on folder selected by user
    setBottomButtonName(action);
    if (action === "previous") {
      // navigate("/list-directory");
      history.push("/list-directory");
    } else if (action === "continue") {
      let newDataArray = [...checkedFiles];
      if (selectedName === "pdfs") {
        newDataArray = newDataArray?.map((item) => ({
          ...item,
          // language: language,
        }));

        setCheckedFiles(newDataArray);
      }
      // setGroupData(newDataArray);

      let data = {
        body: {
          folder_name: localStorage.getItem("folderName"),
          client_file_details: newDataArray,
          file_type: selectedName,
        },
      };

      if (checkedFiles?.length > 0) {
        console.log(data, "bodyy");
        // isOpen = true;
        // text = "Uploaded";
        // severity = "success";
        dispatch(uploadClientData(data));
        // dispatch(handleClearPDFDeleteData());
        setTimeout(() => {
          // navigate("/review-batch-records-details");
          history.push("/review-batch-records-details");
        }, 3000);
      }
    }
  };

  const onSelectHeaderButton = (buttonSelected = "") => {
    setSelectedButton(buttonSelected);

    // let data = {
    //   folderId: localStorage.getItem("pvalue"),
    //   fileType: buttonSelected,
    // };
    // dispatch(listBoxFiles(data));
    setCheckedFiles([]);
    setGroupData([]);
  };
  const handleLanguage = (name, id) => {
    setLanguage(name);

    const updatedLanguageData = groupData?.map((language) => {
      if (id === language.id) {
        return { ...language, language: name };
      }
      return language;
    });
    setGroupData(updatedLanguageData);
  };
  const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };

  const handleSelectAll = () => {
    //const currentIndex = groupData.indexOf(value);

    const newDataArray = group?.map((item) => ({
      ...item,
    }));
    // setGroupData(newDataArray);

    if (!selectAllChecked) {
      // If "Select All" is checked, set all checkboxes as checked
      //  setCheckedFiles(groupData.map((item) => item.value));
      setCheckedFiles(newDataArray);
    } else {
      // If "Select All" is unchecked, clear all checkboxes
      setCheckedFiles([]);
    }
    console.log(checkedFiles, "checkdedede filewda");
    console.log(newDataArray, "checkdedede filewda");
    // Toggle the state of "Select All" checkbox
    setSelectAllChecked(!selectAllChecked);
  };

  const isDisabled = (selectedName, language, checkedFiles) => {
    if (
      selectedName === "pdfs" &&
      // (language === "" || checkedFiles.length <= 0)
      checkedFiles.length <= 0
    ) {
      return true;
    } else if (selectedName !== "pdfs" && checkedFiles.length <= 0) {
      return true;
    } else return false;
  };

  const handleBack = () => {
    // navigate("/list-directory");
    history.push("/list-directory");
  };

  return (
    <>
      <Header page="new-project" />
      {isOpen ? (
        <CustomizedSnackbars
          className="popup"
          handleClose={() => {
            setIsOpen(false);
          }}
          severity={severity}
          state={isOpen}
          text={message}
        />
      ) : (
        <></>
      )}
      <Box>
        <Grid className="sidebar-main" container>
          <Grid item className="sidebar-container" md={3} sm={4}>
            <LeftSideBar selectedKey={"three"} page="sidebar" />
          </Grid>
          <Grid item className="visualization-conatiner" md={9} sm={8}>
            {/* <Box className="uploadBatchHeader-wrap"></Box> */}
            <UploadBatchHeader
              onClick={(e) => {
                onSelectHeaderButton(e);
              }}
              data={data?.upload_in_progress}
              selectedKey={selectedName}
              position="left"
              headerColor="#F5F5F5"
              isDisableOtherBtn={checkedFiles.length > 0}
              handleBack={handleBack}
            />

            {apiStatus?.loader ? (
              <div
                style={{ height: "calc(100vh - 100px)", alignItems: "center" }}
              >
                {" "}
                <ProgressBar />
              </div>
            ) : groupData?.length ? (
              <Grid container>
                <Grid
                  // className="scroll-wrapper"
                  item
                  xs={8}
                  style={{
                    height: "calc(100vh - 144px)",
                    overflowY: "scroll",
                  }}
                >
                  <div
                    style={{
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                      background: "#fff",
                    }}
                  >
                    {/* {selectedName === "pdfs" && (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            paddingLeft: "44px",
                            alignItems: "center",
                            paddingTop: "10px",
                          }}
                        >
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={selectAllChecked}
                                  onChange={handleSelectAll}
                                />
                              }
                              label="Select All"
                            />
                          </FormGroup>
                          <FormControl
                            size="small"
                            sx={{
                              minWidth: 160,
                              maxWidth: 180,
                              marginRight: 5,
                              // maxHeight: "201px !important",
                            }}
                          >
                            <InputLabel id="demo-simple-select-helper-label">
                              Select Language
                            </InputLabel>

                            <Select
                              size="small"
                              fullWidth
                              labelId="language-select-label"
                              id="language-select"
                              // defaultValue="sl"
                              label="Select Language"
                              onChange={(event) =>
                                handleLanguage(event.target.value)
                              }
                              value={language}
                              className="lang-select"
                            >
                           
                              {languageData.map((language, index) => (
                                <MenuItem
                                  // sx={{
                                  //   display: "flex",
                                  //   justifyContent: "center",
                                  //   alignItems: "center",
                                  // }}
                                  key={index}
                                  value={language.value}
                                >
                                  <ListItemText primary={language.name} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>

                        <Divider style={{ paddingTop: "10px" }} />
                      </>
                    )} */}
                  </div>

                  {/* <List className="scroller check-box-style"> */}
                  <List className="check-box-style">
                    {groupData?.map((value, index) => {
                      return (
                        <CheckboxTitle
                          key={index}
                          checked={checkedFiles}
                          onClick={(e) => handleToggle(e)}
                          value={value}
                          selectedName={selectedName}
                          isCheckboxChecked={isCheckboxChecked}
                          language={(name, id) => handleLanguage(name, id)}
                          handleCheckboxChange={handleCheckboxChange}
                          selectAllChecked={selectAllChecked}
                        ></CheckboxTitle>
                      );
                    })}
                  </List>
                </Grid>

                <Grid
                  //  className="scroller scroll-wrapper"
                  item
                  xs={4}
                  style={{
                    height: "calc(100vh - 144px)",
                    overflowY: "auto",
                  }}
                >
                  <Grid container className="selected-file-margin" spacing={2}>
                    {checkedFiles?.map((value, index) => (
                      <Grid key={index} item xs={11} sx={{}}>
                        <SelectedFileList
                          onClick={() => {
                            removeSelectedFile(index);
                          }}
                          value={value}
                        ></SelectedFileList>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                {/* <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <BottomButtonsList
                  onClick={(e, action) => {
                    onSelectBottomButton(e, action);
                  }}
                  selectedKey={selectedBottomButton}
                  // isNextDisable={language === "" || language === "sl"}
                  isNextDisable={
                    isDisabled(selectedName, language, checkedFiles)
                    // selectedName === "pdfs" &&
                    // language === "" &&
                    // checkedFiles.length <= 0
                  }
                  position="end"
                  headerColor="#FFFFF"
                  isPrevious={true}
                ></BottomButtonsList>
              </Grid> */}

                <AdminFooter
                  onHandleClick={(e, action) => {
                    onSelectBottomButton(e, action);
                  }}
                  isSubmitDisable={isDisabled(
                    selectedName,
                    language,
                    checkedFiles
                  )}
                />
              </Grid>
            ) : (
              !apiStatus?.loader && (
                <div className="no-data">
                  <Label
                    text="No Data Found"
                    fontSize="16"
                    fontWeight="600"
                    padding="20px"
                  />
                </div>
              )
            )}
          </Grid>
        </Grid>
        {err && (
          <CustomizedSnackbars
            severity="error"
            state={err}
            text={message}
            handleClose={() => setErr(false)}
          />
        )}
      </Box>
    </>
  );
};

export default Index;
