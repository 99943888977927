import React, { useEffect, useState } from "react";
import Header from "../../../components/header/Header";
import LeftSideBar from "../../../components/LeftSideBar/LeftSideBar";
import { Box, Grid } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import * as acts from "../../../redux/administrator/actions";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, MenuItem, Menu } from "@mui/material";
import AdministratorHeader from "../../../components/header/AdministratorHeader";
import moment from "moment";
import "./ManageUser.scss";
import TablePagination from "@mui/material/TablePagination";
import CustomizedSnackbars from "../../../components/snackbars/CustomizedSnackbars";

const Index = () => {
  const Dispatch = useDispatch();
  const history = useHistory();
  const data = useSelector((state) => state?.admin?.userState);
  const apiStatus = useSelector((state) => state?.admin?.uploadData);
  const [isOpen, setIsOpen] = useState(false);
  const [text, setText] = useState("");
  const [severity, setSeverity] = useState("");
  let userData = [];
  if (data?.data?.message == "user deleted") {
    setIsOpen(true);
    setText("User deleted");
    setSeverity("success");
    setTimeout(() => {
      Dispatch(acts.getUserList());
    }, 2000);
  } else {
    userData = data?.data;
  }

  const MyOptions = ["Edit User", "Delete User"];
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userdata, setUserData] = React.useState(null);
  const [pageCount, setPageCount] = useState(data?.total_count || 0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [page, setPage] = useState(0);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleData = (data) => {
    setUserData(data);
  };

  const handleClickOnOption = (option) => {
    handleClose();
    if (option == "Edit User") {
      history.push("/user-form", userdata);
    } else {
      Dispatch(acts.deleteUser(userdata.id));
      history.push("/manage-user");
    }
  };

  useEffect(() => {
    if (apiStatus && apiStatus !== undefined) {
      if (apiStatus?.message != null && apiStatus?.next === false) {
        if (apiStatus?.message === "Invalid Token") {
          setIsOpen(true);
          setText(apiStatus?.message);
          setSeverity("error");
        }
      }
    }
  }, [apiStatus]);

  useEffect(() => {
    console.log("page..............");
    Dispatch(
      acts.getUserList({
        page: page + 1,
        page_size: rowsPerPage,
      })
    );
  }, [Dispatch ,rowsPerPage]);

  const handlePageChange = (event, value) => {
    setPage(value);
    Dispatch(
      acts.getUserList({
        page: value + 1,
        page_size: rowsPerPage,
      })
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };
  return (
    <>
      <Header page="new-project" />
      {isOpen ? (
        <CustomizedSnackbars
          className="popup"
          handleClose={() => {
            isOpen = false;
          }}
          severity={severity}
          state={isOpen}
          text={text}
        />
      ) : (
        <></>
      )}
      <Box>
        <Grid container>
          <Grid item md={3} sm={4}>
            <LeftSideBar page="sidebar" />
          </Grid>
          <Grid item md={9} sm={8}>
            <AdministratorHeader
              position="right"
              text="Add New User"
              isShow={true}
              to="/user-form"
            />
            <Box className="marginRight">
              <TableContainer
                className="manageUser-container"
                component={Paper}
              >
                <Table stickyHeader aria-label="simple table" className="parent-table">
                  <TableHead className="parent-table-th">
                    <TableRow className="parent-table-row">
                      <TableCell align="left"></TableCell>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="left">Email</TableCell>
                      <TableCell align="left">Role</TableCell>
                      <TableCell align="left">Created Date</TableCell>
                      <TableCell align="left">Status</TableCell>
                      <TableCell align="left">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userData?.length > 0 &&
                      userData?.map((data) => {
                        return (
                          <>
                            <TableRow key={data.id} className="border-0">
                              <TableCell>
                                <span className="checkboxicon"></span>
                              </TableCell>
                              <TableCell align="left">
                                {data?.username}
                              </TableCell>
                              <TableCell align="left">{data?.email}</TableCell>
                              <TableCell align="left">{data?.groups}</TableCell>
                              <TableCell align="left">
                                {moment(data?.date_joined).format(
                                  "MMM DD YYYY"
                                )}
                              </TableCell>
                              <TableCell align="left">{data?.status}</TableCell>
                              <TableCell>
                                <IconButton
                                  aria-label="more"
                                  onClick={handleClick}
                                  aria-haspopup="true"
                                  aria-controls="long-menu"
                                >
                                  <MoreVertIcon
                                    onClick={() => {
                                      handleData(data);
                                    }}
                                  />
                                </IconButton>
                                <Menu
                                  anchorEl={anchorEl}
                                  keepMounted
                                  onClose={handleClose}
                                  open={open}
                                >
                                  {MyOptions.map((option) => (
                                    <MenuItem
                                      key={option}
                                      onClick={() => {
                                        handleClickOnOption(option);
                                      }}
                                    >
                                      {option}
                                    </MenuItem>
                                  ))}
                                </Menu>
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Grid className="pagination-wrap pagination-bg">
              <TablePagination
                component="div"
                rowsPerPageOptions={[15, 25, 50, 100]}
                count={parseInt(pageCount)}
                page={parseInt(page)}
                onPageChange={handlePageChange}
                rowsPerPage={parseInt(rowsPerPage)}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Index;
