import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import "./footer.scss";

const Footer = ({
  onHandleClick,
  isShowSubmit,
  isShowPrevious,
  isPreviousDisable,
  isSubmitDisable,
  previousLabel,
  submitLabel,
}) => {
  return (
    <Box className="AdminFooter">
      {isShowPrevious && (
        <Button
          className="Pre-next-btn"
          size="medium"
          disabled={isPreviousDisable}
          onClick={(event) => onHandleClick(event, "previous")}
          startIcon={<ArrowBackIosIcon />}
        >
          {previousLabel}
        </Button>
      )}

      {isShowSubmit && (
        <Button
          className="btn-contained"
          disabled={isSubmitDisable}
          onClick={(event) => onHandleClick(event, "continue")}
          size="medium"
        >
          {submitLabel}
        </Button>
      )}
    </Box>
  );
};

Footer.defaultProps = {
  isShowSubmit: true,
  isShowPrevious: true,
  isPreviousDisable: false,
  isSubmitDisable: false,
  previousLabel: "Previous",
  submitLabel: "Submit",
};
export default Footer;
