import {
  Box,
  Grid,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Collapse,
} from "@mui/material";
import React, { useEffect, useState, useMemo } from "react";
import Buttons from "../../../components/Controls/buttons/Button";
import Header from "../../../components/header/Header";
import LeftSideBar from "../../../components/LeftSideBar/LeftSideBar";
import * as acts from "../../../redux/administrator/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Radio from "@mui/material/Radio";
import FolderIcon from "@mui/icons-material/Folder";
import ProgressBar from "../../../components/ProgressBar";
import "./mergePdf.scss";
import CustomizedSnackbars from "../../../components/snackbars/CustomizedSnackbars";
import AdministratorHeader from "../../../components/header/AdministratorHeader";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  ExpandLess,
  ExpandMore,
  Folder,
  FolderOpen,
} from "@mui/icons-material";
import DashboardTableSkaleton from "../../../components/Skeletons/DashboardTableSkaleton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function ListDirectory() {
  const Dispatch = useDispatch();
  // const navigate = useNavigate();
  const data = useSelector((state) => state?.admin?.batchTypes);
  const refreshBoxFolder = useSelector(
    (state) => state?.admin?.refreshBoxFolder
  );
  const history = useHistory();

  // let folderData = useSelector((state) => state?.admin?.subFolder);
  // const [subFolder, setSubFolder] = React.useState(null);

  // useEffect(() => {
  //   setSubFolder(folderData?.data);
  // }, [folderData]);
  const [value, setValue] = React.useState(null);
  const [folderName, setFolderName] = React.useState("");
  const apiStatus = useSelector((state) => state?.admin?.apiStatus);

  const [message, setMessage] = useState("");
  const [err, setErr] = useState(false);
  const [expandedNodes, setExpandedNodes] = useState([]);
  const [expandedData, setExpandedData] = useState([]);
  // const [id, setId] = useState("");
  const [openFolders, setOpenFolders] = useState({});
  const [folderId, setFolderId] = React.useState(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   if (!expandedData[id]) {
  //     setExpandedData({
  //       ...expandedData,
  //       [id]: folderData?.data,
  //     });
  //     if (expandedNodes.includes(id)) {
  //       setExpandedNodes(expandedNodes.filter((nodeId) => nodeId !== id));
  //     } else {
  //       setExpandedNodes([...expandedNodes, id]);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [folderData]);

  useEffect(() => {
    Dispatch(acts.getBatchRecordType({next:false}));
    // Dispatch(acts.getDrugTypeList());
  }, [Dispatch]);

  useEffect(() => {
    if (apiStatus && apiStatus !== undefined) {
      if (apiStatus?.message !== null && apiStatus?.next === false) {
        if (apiStatus?.message === "Invalid Token") {
          setErr(true);
          setMessage(apiStatus?.message);
        }
      }
    }
  }, [apiStatus]);

  useEffect(() => {
    if (
      refreshBoxFolder?.loader === false &&
      refreshBoxFolder?.apiSuccess === true &&
      refreshBoxFolder?.notification === true &&
      refreshBoxFolder?.next === true
    ) {
      setMessage(apiStatus?.message);
      setSuccess(true);
      setLoading(false);

      Dispatch(acts.getBatchRecordType());
      // Dispatch(acts.getDrugTypeList());
      // Dispatch(acts.clearRefreshBoxFoldersStatus());
    } else if (
      refreshBoxFolder?.loader === false &&
      refreshBoxFolder?.apiError === true &&
      refreshBoxFolder?.notification === false &&
      refreshBoxFolder?.next === false
    ) {
      setLoading(false);
      // Dispatch(acts.clearRefreshBoxFoldersStatus());
      setErr(true);
      setMessage(refreshBoxFolder?.message);
    }
  }, [refreshBoxFolder]);

  const handleClick = (data) => {
    setValue(data?.id);
  };

  const handleContine = () => {
    let sendData = {
      value,
      folderName,
    };
    // navigate("/merge-pdf-select", sendData);
    // navigate("/merge-pdf-select", { state: { sendData } });
    history.push({
      pathname: "/merge-pdf-select",
      state: sendData,
    });
  };

  // const handleExpand = async (id) => {
  // setId(id);
  // await Dispatch(acts.getProductTypeSubFolder(id));
  // };

  // const isNodeExpanded = (id) => expandedNodes.includes(id);

  // const memoizedRenderTree = useMemo(() => {
  //   const cache = {};

  //   const renderTreeNode = (items) => {
  //     if (!Array.isArray(items) || items.length === 0) {
  //       return null;
  //     }

  //     return items.map((item) => {
  //       if (!cache[item.id]) {
  //         cache[item.id] = (
  //           <TreeItem
  //             key={item.id}
  //             nodeId={item.id}
  //             label={
  //               <div className="tree-item-div">
  //                 <Radio
  //                   id={item.id}
  //                   onClick={() => {
  //                     handleClick(item);
  //                   }}
  //                   className="mr-10"
  //                 />

  //                 <FolderIcon className="folder-icon" />
  //                 {/* first level folder............ */}
  //                 <p>{item.name}</p>

  //                 <div className="ml-auto">
  //                   <Button onClick={() => handleExpand(item.id)}>
  //                     {isNodeExpanded(item.id) ? (
  //                       <ExpandMoreIcon />
  //                     ) : (
  //                       <ChevronRightIcon />
  //                     )}
  //                   </Button>
  //                 </div>
  //               </div>
  //             }
  //           >
  //             {renderTreeNode(expandedData[item.id])}
  //           </TreeItem>
  //         );
  //       }
  //       return cache[item.id];
  //     });
  //   };
  //   return renderTreeNode(data?.data);
  // }, [data, expandedData, isNodeExpanded, handleExpand]);

  const handleFolderClick = (folder) => {
    setOpenFolders({
      ...openFolders,
      [folder.id]: !openFolders[folder.id],
    });
  };

  const handleRadioChange = (folder) => {
    // setSelectedFolder(folder.id);
    setFolderId(folder?.id);
  };

  const renderTree = (nodes, level) => (
    <List disablePadding>
      {nodes?.map((node) => (
        <React.Fragment key={node.id}>
          <ListItem
            button
            style={{ paddingLeft: level * 20 }}
            onClick={() => handleFolderClick(node)}
          >
            <Radio
              onClick={() => handleClick(node)}
              checked={folderId === node.id}
              onChange={() => handleRadioChange(node)}
            />
            <ListItemIcon style={{ paddingLeft: "10px" }}>
              {node.subfolders ? (
                openFolders[node.id] ? (
                  <FolderOpen />
                ) : (
                  <Folder className="folder-icon" />
                )
              ) : (
                <Folder className="folder-icon" />
              )}
            </ListItemIcon>
            <ListItemText primary={node.name} />
            {node.subfolders &&
              (openFolders[node.id] ? <ExpandLess /> : <ExpandMore />)}
          </ListItem>
          <Divider />
          {node.subfolders && (
            <Collapse in={openFolders[node.id]} timeout="auto" unmountOnExit>
              {renderTree(node.subfolders, level + 1)}
            </Collapse>
          )}
        </React.Fragment>
      ))}
    </List>
  );

  const handleRefresh = () => {
    Dispatch(acts.refreshBoxFolders());
    setLoading(true);
  };

  return (
    <div>
      <Header />
      <Box className="list-directory-merge-pdf">
        <Grid className="sidebar-main" container>
          <Grid item className="sidebar-container" md={3} sm={4}>
            <LeftSideBar page="sidebar" />
          </Grid>
          <Grid item className="visualization-conatiner header-select" md={9} sm={8}>
            <AdministratorHeader
              position="right"
              text="Merge Pdf"
              to="/review-batch-records-details"
              showRefreshBtn={true}
              handleRefresh={handleRefresh}
            />

            <Box
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingRight: "10px",
              }}
            >
              {/* <Box style={{ paddingRight: "12px" }}> */}
              {/* <Buttons
                text="Refresh"
                variant="contained"
                onClick={handleRefresh}
                className="btn"
              /> */}
              {/* </Box> */}

              {/* <Box className="Add-batch-records"> */}
              <Buttons
                text="Continue"
                disabled={!value}
                onClick={handleContine}
                className="btn"
              />
              {/* </Box> */}
            </Box>
            <Divider />
            {apiStatus?.loader ? (
              <DashboardTableSkaleton />
            ) : (
              <div className="dir-container">{renderTree(data?.data, 0)}</div>
            )}
            {/* <Grid container className="list_directory-container">
              <TreeView
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                multiSelect
              >
                {memoizedRenderTree}
              </TreeView>
              {folderData?.loader === true ? <ProgressBar /> : ""}
            </Grid> */}

            {err && (
              <CustomizedSnackbars
                severity="error"
                state={err}
                text={message}
                handleClose={() => setErr(false)}
              />
            )}
          </Grid>
        </Grid>
      </Box>
      {loading && <ProgressBar />}
    </div>
  );
}
