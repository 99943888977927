import React from "react";
import HeaderButton from "../Controls/buttons/HeaderButton";
import "./index.scss";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const header = ({
  headerColor,
  onClick,
  position,
  selectedKey,
  data,
  isDisableOtherBtn,
  handleBack,
}) => {
  return (
    <div
      className="project-main-header"
      style={{
        background: headerColor,
        justifyContent: position,
      }}
    >
      <IconButton onClick={handleBack} style={{ paddingRight: "10px" }}>
        <ArrowBackIcon />
      </IconButton>

      <HeaderButton
        onClick={() => {
          onClick("pdfs");
        }}
        // className={
        //   selectedKey.toLowerCase() === "pdfs"
        //     ? "header-btn"
        //     : "header-btn-selected"
        // }
        // isDisabled={data}
        text={"pdf"}
        cusStyle={{ margin: "4px", minWidth: "230px", height: "30px" }}
        variant={
          selectedKey.toLowerCase() === "pdfs" ? "contained" : "outlined"
        }
        isDisabled={selectedKey !== "pdfs" && isDisableOtherBtn}
      ></HeaderButton>
      <HeaderButton
        size="larger"
        onClick={() => {
          onClick("template");
        }}
        text={"template"}
        cusStyle={{ margin: "4px", minWidth: "230px", height: "30px" }}
        // className={
        //   selectedKey.toLowerCase() === "template"
        //     ? "header-btn"
        //     : "header-btn-selected"
        // }
        variant={
          selectedKey.toLowerCase() === "template" ? "contained" : "outlined"
        }
        isDisabled={selectedKey !== "template" && isDisableOtherBtn}
      ></HeaderButton>
      <HeaderButton
        text={"output format"}
        variant={
          selectedKey.toLowerCase() === "op_format" ? "contained" : "outlined"
        }
        cusStyle={{ margin: "4px", minWidth: "230px", height: "30px" }}
        onClick={() => {
          onClick("op_format");
        }}
        // className={
        //   selectedKey.toLowerCase() === "op_format"
        //     ? "header-btn"
        //     : "header-btn-selected"
        // }
        isDisabled={selectedKey !== "op_format" && isDisableOtherBtn}
      ></HeaderButton>
    </div>
  );
};

export default header;
