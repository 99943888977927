import {
  Box,
  Grid,
  TextField,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Divider,
  IconButton,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState, useMemo } from "react";
import Buttons from "../../../components/Controls/buttons/Button";
import Header from "../../../components/header/Header";
import LeftSideBar from "../../../components/LeftSideBar/LeftSideBar";
import * as acts from "../../../redux/administrator/actions";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FolderIcon from "@mui/icons-material/Folder";
import AddIcon from "@mui/icons-material/Add";
import ProgressBar from "../../../components/ProgressBar";
import "./index.scss";
import CustomizedSnackbars from "../../../components/snackbars/CustomizedSnackbars";
import AdministratorHeader from "../../../components/header/AdministratorHeader";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  ExpandLess,
  ExpandMore,
  Folder,
  FolderOpen,
} from "@mui/icons-material";
import Label from "../../../components/label/Label";
import BatchinfoTable from "../../../components/BatchInfoTable";
import ClearIcon from "@mui/icons-material/Clear";
import DashboardTableSkaleton from "../../../components/Skeletons/DashboardTableSkaleton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function ListDirectory() {
  const Dispatch = useDispatch();
  // const navigate = useNavigate();
  const history = useHistory();

  const data = useSelector((state) => state?.admin?.batchTypes);
  const productType = useSelector((state) => state?.admin?.productType);
  // let folderData = useSelector((state) => state?.admin?.subFolder);
  const refreshBoxFolder = useSelector(
    (state) => state?.admin?.refreshBoxFolder
  );

  const [folderId, setFolderId] = React.useState(null);
  const [folderName, setFolderName] = React.useState("");
  const [folderNameFromSelection, setFolderNameFormSelection] =
    React.useState("");
  const [disableTextField, setDisableTextField] = React.useState();
  const [isdisableListSelection, setDisableListSelection] = React.useState();

  const apiStatus = useSelector((state) => state?.admin?.apiStatus);
  const [message, setMessage] = useState("");
  const [err, setErr] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  // const [expandedNodes, setExpandedNodes] = useState([]);
  // const [expandedData, setExpandedData] = useState([]);
  // const [id, setId] = useState("");

  const [openFolders, setOpenFolders] = useState({});
  // const [selectedFolder, setSelectedFolder] = useState(null);

  const handleFolderClick = (folder) => {
    setOpenFolders({
      ...openFolders,
      [folder.id]: !openFolders[folder.id],
    });
  };

  const handleRadioChange = (folder) => {
    // setSelectedFolder(folder.id);
    setFolderId(folder?.id);
  };

  // useEffect(() => {
  //   if (!expandedData[id]) {
  //     setExpandedData({
  //       ...expandedData,
  //       [id]: folderData?.data,
  //     });
  //     if (expandedNodes.includes(id)) {
  //       setExpandedNodes(expandedNodes.filter((nodeId) => nodeId !== id));
  //     } else {
  //       setExpandedNodes([...expandedNodes, id]);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [folderData]);

  useEffect(() => {
    //Success and error notification
    if (apiStatus && apiStatus !== undefined) {
      if (apiStatus?.apiError === true && apiStatus?.next === false) {
        setMessage(apiStatus?.message);
        setErr(true);
      }
    }
  }, [apiStatus]);

  useEffect(() => {
    Dispatch(acts.getBatchRecordType());
    Dispatch(acts.getDrugTypeList());
  }, [Dispatch]);

  useEffect(() => {
    if (apiStatus && apiStatus !== undefined) {
      if (apiStatus?.message !== null && apiStatus?.next === false) {
        if (apiStatus?.message === "Invalid Token") {
          setMessage(apiStatus?.message);
          setErr(true);
        }
      }
    }
  }, [apiStatus]);

  useEffect(() => {
    if (
      refreshBoxFolder?.loader === false &&
      refreshBoxFolder?.apiSuccess === true &&
      refreshBoxFolder?.notification === true &&
      refreshBoxFolder?.next === true
    ) {
      setMessage(apiStatus?.message);
      setSuccess(true);
      setLoading(false);

      Dispatch(acts.getBatchRecordType());
      Dispatch(acts.getDrugTypeList());
      // Dispatch(acts.clearRefreshBoxFoldersStatus());
    } else if (
      refreshBoxFolder?.loader === false &&
      refreshBoxFolder?.apiError === true &&
      refreshBoxFolder?.notification === false &&
      refreshBoxFolder?.next === false
    ) {
      setLoading(false);
      // Dispatch(acts.clearRefreshBoxFoldersStatus());
      setErr(true);
      setMessage(refreshBoxFolder?.message);
    }
  }, [refreshBoxFolder]);

  // const handleClick = (data) => {
  //   setFolderId(data?.id);
  // };

  const onChangeNewDrugType = (event) => {
    const inputValue = event.target.value;

    // Check if the input starts with a special character or space
    if (/^[~`!@#$%^&*()_+={}[\]:;<>,.?/"'\\ ]/.test(inputValue)) {
      // Don't update the state
      setFolderName("");
    } else {
      // Update the state
      setFolderName(inputValue);

      // setDisableTextField(true);
      setDisableListSelection(true);
      setFolderNameFormSelection("");
    }
  };

  const handleClearTextField = (data) => {
    // if (folderName === "") {
    //   return;
    // } else {
    // setDisableTextField(true);
    setFolderName("");
    setDisableListSelection(false);
    // }
  };
  // const isFolderNameValid = () => {
  //   // Check if folderName is not empty and doesn't start with a special character or space
  //   return (
  //     folderName.trim() !== "" &&
  //     /^[^~`!@#$%^&*()_+={}[\]:;<>,.?/"'\\ ]+$/.test(folderName[0])
  //   );
  // };
  const handleContine = () => {
    if (
      // disableTextField === false &&
      folderId &&
      (folderName || folderNameFromSelection)
    ) {
      let sendData = {
        value: folderId,
        folderName: folderName || folderNameFromSelection,
      };
      // navigate("/upload-batch", sendData);
      // navigate("/upload-batch", { state: sendData });
      // history.push("/upload-batch");
      history.push({
        pathname: "/upload-batch",
        state: sendData,
      });
    }
  };

  const handleChangeSelect = (event) => {
    setFolderNameFormSelection(event.target.value);
    setFolderName("");
    setDisableTextField(true);
  };

  const handleRefresh = () => {
    // Dispatch(acts.refreshBoxFolders());
    setLoading(true);
  };

  const renderTree = (nodes, level) => (
    <List disablePadding>
      {nodes?.map((node) => (
        <React.Fragment key={node.id}>
          <ListItem
            button
            style={{ paddingLeft: level * 20 }}
            onClick={() => handleFolderClick(node)}
          >
            <Radio
              checked={folderId === node.id}
              onChange={() => handleRadioChange(node)}
            />
            <ListItemIcon style={{ paddingLeft: "10px" }}>
              {node.subfolders ? (
                openFolders[node.id] ? (
                  <FolderOpen />
                ) : (
                  <Folder className="folder-icon" />
                )
              ) : (
                <Folder className="folder-icon" />
              )}
            </ListItemIcon>
            <ListItemText primary={node.name} />
            {node.subfolders &&
              (openFolders[node.id] ? <ExpandLess /> : <ExpandMore />)}
          </ListItem>
          <Divider />
          {node.subfolders && (
            <Collapse in={openFolders[node.id]} timeout="auto" unmountOnExit>
              {renderTree(node.subfolders, level + 1)}
            </Collapse>
          )}
        </React.Fragment>
      ))}
    </List>
  );

  return (
    <div>
      <Header />
      <Box className="list-directory">
        <Grid className="sidebar-main" container>
          <Grid item className="sidebar-container" md={3} sm={4}>
            <LeftSideBar page="sidebar" />
          </Grid>
          {/* <Grid className="header-select" item md={9} sm={8}> */}
          <Grid className="visualization-conatiner " item md={9} sm={8}>
            <AdministratorHeader
              position="right"
              text="Upload Batch Records"
              isShow={true}
              to="/review-batch-records-details"
              handleRefresh={handleRefresh}
              showRefreshBtn={true}
            />
            {/* <BatchinfoTable header={header} /> */}

            <Box className="Add-batch-records">
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={8}>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={4}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-autowidth-label">
                          Select Drug Type
                        </InputLabel>
                        <Select
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          labelId="demo-simple-select-autowidth-label"
                          id="demo-simple-select"
                          value={folderNameFromSelection}
                          onChange={handleChangeSelect}
                          autoWidth
                          disabled={isdisableListSelection}
                          label="Select Drug Type"
                          startAdornment={
                            folderNameFromSelection && (
                              <IconButton
                                className="btn-project-search"
                                onClick={() => {
                                  setFolderNameFormSelection("");
                                  setDisableTextField(false);
                                }}
                                edge="start"
                              >
                                <ClearIcon />
                              </IconButton>
                            )
                          }
                        >
                          <MenuItem disabled value="">
                            <span></span>
                          </MenuItem>
                          {productType?.map((list) => (
                            <MenuItem
                              key={list}
                              value={list.name}
                              className="batch-product-type"
                            >
                              {list.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <Label
                        text="Or"
                        fontSize="16px"
                        fontWeight="500"
                        className="ProductTypeList"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        size="small"
                        label="Enter New Drug Type"
                        value={folderName}
                        id="outlined-basic"
                        variant="outlined"
                        disabled={disableTextField}
                        onChange={onChangeNewDrugType}
                        InputProps={{
                          startAdornment: folderName && (
                            <InputAdornment position="start">
                              <IconButton
                                aria-label="clear input"
                                onClick={handleClearTextField}
                                edge="start"
                                disabled={disableTextField}
                                size="small"
                              >
                                <ClearIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Buttons
                        text="Continue"
                        variant="contained"
                        disabled={
                          folderId === null ||
                          (folderName === "" && folderNameFromSelection === "")
                        }
                        onClick={handleContine}
                        className="btn-continue"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* <Grid item xs={4} container justifyContent="flex-end">
                  <Box style={{ paddingRight: "12px" }}>
                    <Buttons
                      text="Refresh"
                      variant="contained"
                      onClick={handleRefresh}
                      className="btn-continue"
                    />
                  </Box>
                </Grid> */}
              </Grid>
            </Box>

            {apiStatus?.loader ? (
              <DashboardTableSkaleton />
            ) : (
              <div className="dir-container">{renderTree(data?.data, 0)}</div>
            )}
            {err && (
              <CustomizedSnackbars
                severity="error"
                state={err}
                text={message}
                handleClose={() => setErr(false)}
              />
            )}
            {/* <BoxFolders /> */}
          </Grid>
        </Grid>
      </Box>
      {loading && <ProgressBar />}
    </div>
  );
}
