import {
  GET_AUDIT_DETAILS,
  GET_AUDIT_LIST,
  ADD_GROUP,
  REJECT_OUT_PUT_FILE_BATCH,
  EDIT_GROUP,
  GET_GROUP_LIST,
  DELETE_GROUP,
  API_STATUS,
  ADD_USER,
  EDIT_USER,
  GET_USER_LIST,
  DELETE_USER,
  GET_DELETED_DATA,
  GET_OUT_PUT_LIST,
  GET_OUT_PUT_URL,
  REJECT_OUT_PUT_FILE,
  SUBMIT_OUT_PUT_FILE,
  BATCH_TYPE,
  LIST_BOX_FILES,
  UPLOAD_DATA,
  PRODUCT_TYPE,
  PRODUCT_SUB_FOLDER,
  GET_BATCH_DATA,
  LIST_BATCH_RECORD,
  MANAGE_GROUP,
  SUBMIT_OUT_PUT_FILE_UPLOAD,
  GET_USER_ACTIVITY,
  GET_USER_NAME,
  GET_FILTERED_USER_ACTIVITY,
  GET_DATA_POINT_CALCULATION,
  GET_DP_DETAILS,
  GET_MERGE_BATCHES,
  GET_MERGE_PROJECTS,
  API_STATUS_MASTER,
  CLEAR_API_STATUS,
  GET_MERGED_FILES,
  CLEAR_GET_MERGED_FILES,
  HANDLE_REFRESH_BOX_FOLDERS,
} from "./types";
import { initialState } from "./initialState";

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case API_STATUS_MASTER:
      return {
        ...state,
        apiStatus: action.payload,
      };

    case CLEAR_API_STATUS:
      return {
        ...state,
        apiStatus: action.payload,
      };

    case API_STATUS:
      return {
        ...state,
        uploadData: action.payload,
      };
    case ADD_GROUP:
      return {
        ...state,
        group: action.payload,
      };
    case EDIT_GROUP:
      return {
        ...state,
        group: action.payload,
      };
    case GET_GROUP_LIST:
      return {
        ...state,
        group: action.payload,
      };
    case DELETE_GROUP:
      return {
        ...state,
        group: action.payload,
      };
    case ADD_USER:
      return {
        ...state,
        userState: action.payload,
      };
    case EDIT_USER:
      return {
        ...state,
        userState: action.payload,
      };
    case GET_USER_LIST:
      return {
        ...state,
        userState: action.payload,
      };
    case DELETE_USER:
      return {
        ...state,
        userState: action.payload,
      };
    case GET_DELETED_DATA:
      return {
        ...state,
        deletedData: action.payload,
      };
    case GET_OUT_PUT_LIST:
      return {
        ...state,
        outPutData: action.payload,
      };
    case GET_OUT_PUT_URL:
      return {
        ...state,
        outPutUrl: action.payload,
      };
    case REJECT_OUT_PUT_FILE:
      return {
        ...state,
        outPutRes: action.payload,
      };
    case SUBMIT_OUT_PUT_FILE:
      return {
        ...state,
        outPutRes: action.payload,
      };
    case SUBMIT_OUT_PUT_FILE_UPLOAD:
      return {
        ...state,
        outPutUpload: action.payload,
      };
    case REJECT_OUT_PUT_FILE_BATCH:
      return {
        ...state,
        rejectOutPutBatch: action.payload,
      };
    case BATCH_TYPE:
      return {
        ...state,
        batchTypes: action.payload,
      };

    case LIST_BOX_FILES:
      return {
        ...state,
        boxFiles: action.payload?.data,
      };
    case UPLOAD_DATA:
      return {
        ...state,
        uploadData: action.payload,
      };
    case PRODUCT_TYPE:
      return {
        ...state,
        productType: action.payload,
      };
    case PRODUCT_SUB_FOLDER:
      return {
        ...state,
        subFolder: action.payload,
      };
    case GET_BATCH_DATA:
      return {
        ...state,
        batchDetails: action.payload,
      };
    case LIST_BATCH_RECORD:
      return {
        ...state,
        batchRecord: action.payload,
      };
    case GET_AUDIT_LIST:
      return {
        ...state,
        auditData: action.payload,
      };
    case GET_AUDIT_DETAILS:
      return {
        ...state,
        auditDetails: action.payload,
      };
    case MANAGE_GROUP:
      return {
        ...state,
        groupDetails: action.payload,
      };

    case GET_USER_NAME:
      return {
        ...state,
        userNameByGroup: action.payload,
      };
    case GET_FILTERED_USER_ACTIVITY:
      return {
        ...state,
        filterUserActivityList: action.payload,
      };
    case GET_USER_ACTIVITY:
      return {
        ...state,
        userActivity: action.payload,
      };
    case GET_DATA_POINT_CALCULATION:
      return {
        ...state,
        dataPointCalculation: action.payload,
      };
    case GET_DP_DETAILS:
      return { ...state, dataPointDetail: action.payload };
    case GET_MERGE_PROJECTS:
      return { ...state, mergeProjects: action.payload };
    case GET_MERGE_BATCHES:
      return { ...state, mergeBatches: action.payload };
    case GET_MERGED_FILES: {
      return {
        ...state,
        mergedFiles: action?.payload,
      };
    }
    case CLEAR_GET_MERGED_FILES: {
      return {
        ...state,
        mergedFiles: action?.payload,
      };
    }
    case HANDLE_REFRESH_BOX_FOLDERS:{
      return {
        ...state,
        refreshBoxFolder:action?.payload
      }
    }
    default:
      return state;
  }
};

export default reducer;
