import AxiosService from "./axiosServices";
const axiosService = new AxiosService();
export default class AdministratorService {
  getManageGroupList = () => {
    return axiosService.Get(`api/manage-groups/`);
  };

  getUsersByGroup = (groupName) => {
    const data = {
      group_name: groupName,
    };
    return axiosService.Post(`api/get-user-by-group/`, data);
  };

  addNewGroup = (data) => {
    return axiosService.Post(`api/manage-groups/`, data);
  };

  deleteGroup = (data) => {
    return axiosService.Delete(`api/group-detail/${data}/`, true);
  };

  updateGroup = (data, id) => {
    return axiosService.Put(`api/group-detail/${id}/`, data, true);
  };

  //FOR MANAGE USER
  getUserList = (data) => {
    const params = {
      page_size: data?.page_size,
      page: data?.page,
    };
    return axiosService.Get(`api/manage-users/`,{
      params:params
    });
  };

  addNewUser = (data) => {
    return axiosService.Post(`api/manage-users/`, data);
  };

  deleteUser = (data) => {
    return axiosService.Delete(`api/user-detail/${data}/`, true);
  };

  editUser = (data, id) => {
    return axiosService.Put(`api/user-detail/${id}/`, data, true);
  };

  getDeletedData = (obj) => {
    const { formattedStartDate, formattedEndDate } = obj;
    const params = {
      deleted_after: formattedStartDate,
      deleted_before: formattedEndDate,
    };
    return axiosService.Get(`api/get-deleted-batches/`, {
      params: params,
    });
  };

  getManageOutput = (data) => {
    const params = {
      page_size: data?.page_size,
      page: data?.page,
      file_type: data?.fileType,
      status: data?.status,
      created_after: data?.formattedStartDate,
      created_before: data?.formattedEndDate,
    };
    return axiosService.Get(`api/list-output-files/`, {
      params: params,
    });
  };

  getManageOutputUrl = (data) => {
    return axiosService.Get(`api/preview-output-file/${data}/`);
  };

  submitOutputUpload = (id) => {
    const data = { body: { id: id } };
    return axiosService.Post(`api/approve-data-extraction/ `, data);
  };

  rejectoutputFileBatch = (data) => {
    const data1 = {
      body: {
        batch_id: data.batch_id,
        projectId: data.projectId,
        rejection_remark: data.rejection_remark,
        id: data.id,
      },
    };
    return axiosService.Post(`api/reject-batch/`, data1);
  };

  submitOutput = (data) => {
    return axiosService.Get(`api/approve-data-extraction/${data}/ `);
  };

  getBatchRecordType = () => {
    // return axiosService.Get(`api/list-box-dir/ `);
    return axiosService.Get(`api/list-all-box-dirs/`);
  };

  uploadClientData = (data) => {
    const { file_type } = data.body;
    return axiosService.Post(
      `api/upload-client-data/?file_type=${file_type}`,
      data
    );
  };

  getDrugTypeList = () => {
    return axiosService.Get(`api/get-drug-types/`);
  };

  getProductTypeSubFolder = (data) => {
    return axiosService.Get(`api/list-box-dir/?folder_id=${data}`);
  };

  getBatchDetailsList = (data) => {
    const params = {
      page_size: data?.page_size,
      page: data?.page,
    };
    return axiosService.Get(`api/get-batch-status/`, {
      params: params,
    });
  };

  getBatchRecordList = (data) => {
    const params = {
      page_size: data?.page_size,
      page: data?.page,
    };
    return axiosService.Get(`api/list-batch-records/`, {
      params: params,
    });
  };

  //audit trail list....
  getAuditTrailList = (data) => {
    const params = {
      page_size: data?.page_size,
      page: data?.page,
    };
    return axiosService.Get(`api/audit-trail-list/`, {
      params: params,
    });
  };

  getAuditDetails = (project_id, batch_id) => {
    const params = {
      project_id: project_id,
      batch_id: batch_id,
    };
    return axiosService.Get(`api/get-audit-trails/`, {
      params: params,
    });
  };

  getManageGroup = () => {
    return axiosService.Get(`api/manage-groups/`);
  };

  getUserActivity = (data) => {
    const params = {
      page_size: data?.page_size,
      page: data?.page,
      before: data?.before,
      after: data?.after,
      user_name: data?.user_name,
      group_name: data?.group_name,
    };
    return axiosService.Get(`api/get-user-activities/`, {
      params: params,
    });
  };

  getDataCalculation = (id) => {
    return axiosService.Get(`api/get-data-to-calculate-dp/${id}/`, {});
  };

  calculateDataPoints = (data) => {
    const data1 = {
      body: {
        id: data?.id,
        capture_effort: data?.fileName,
        capture_and_copy_effort: data?.fileName1,
        underscores_used: data?.fileName2,
      },
    };
    return axiosService.Post(`api/calculate-data-points/`, data1);
  };

  getDpDetails = (data) => {
    const params = {
      page_size: data?.page_size,
      page: data?.page,
      updated_after: data?.formattedStartDate,
      updated_before: data?.formattedEndDate,
      file_type: data?.file,
    };
    return axiosService.Get(`api/get-data-point-details/`, {
      params: params,
    });
  };

  getMergeProjectList = () => {
    return axiosService.Get(`api/get-validated-projects/`, {});
  };

  getMergeProjectBatches = (id) => {
    return axiosService.Get(`api/get-project-batches/${id}/`, {});
  };

  submitFinalOpFilesData = (data) => {
    const body = [];
    data.data.map((batch) => {
      let idOfBatches = [];

      batch?.batches.map((batchDetails, i) => {
        idOfBatches = [...idOfBatches, batchDetails?.id];
      });
      body.push({
        sheet_name: batch?.projName,
        files: idOfBatches,
      });
    });

    const dataToSubmit = {
      body: body,
      master_file_name: data?.masterFileName,
    };
    return axiosService.Post(`api/merge-batch-files/`, dataToSubmit);
  };

  mergePdfFiles = (data) => {
    return axiosService.Post(`api/merge-pdfs/`, data);
  };

  createTemplates = (data,template_name) => {
    const body={
    parameter_list:data,
    template_name:template_name
    }
    return axiosService.Post(`api/create-template/`, body);
  };

  getMergedFiles = () => {
    return axiosService.Get(`api/merged-files-list/`, {});
  };
  getAuditHistoryDetail = (batchId) => {
    const params = {
      batch_id:batchId
    }
    return axiosService.Get(`api/get-data-history-details/`, {params:params});
  };

  refreshBoxFolders = () => {
    return axiosService.Get(`api/refresh-box-cache/`, {});
  };
}
